<template>
  <div class="wizard-header__wrapper">
    <slot name="above" />
    <section class="steps-container">
      <div class="hr"></div>
      <div class="steps">
        <div v-for="step of steps" :key="step" class="step-wrapper">
          <span class="step" :class="{ active: isActive(step) }">
            {{ step }}
          </span>
        </div>
      </div>
    </section>
    <slot name="below" />
  </div>
</template>

<script>
export default {
  setup(props) {
    const isActive = step => {
      console.log('step :>> ', step, props.currentStep);
      return props.currentStep >= step;
    };

    return { isActive };
  },
  props: {
    steps: {
      type: Number,
      default: 2,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-header__wrapper {
  position: relative;
  width: 100%;
}
.steps-container {
  position: relative;
}
.steps {
  display: flex;
  justify-content: space-between;
}
.step-wrapper {
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
}
.step {
  background-color: white;
  border: 1px solid $hty-dark-grey;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $hty-dark-grey;
  position: relative;

  &.active {
    background-color: $hty-orange;
    border-color: $hty-orange;
    color: white;
  }
}
.hr {
  width: 100%;
  border-top: 1px solid $hty-dark-grey;
  position: absolute;
  top: 25px;
  z-index: 0;
}
</style>
