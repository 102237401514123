<template>
  <div class="fourth-step">
    <section class="field">
      <InputElement
        :value="$store.getters.adminRegistrationData.taxId"
        @input="$store.commit('setAdminTaxId', $event)"
        :placeholder="$t('serviceProvider.signUp.taxId')"
        validation-rules="required"
      />
    </section>

    <section
      class="drop-item"
      v-for="(companyTypeDoc, i) in $store.getters.companyTypeDocs"
      :key="i"
    >
      <label class="label">{{ companyTypeDoc.name }}</label>
      <ValidationProvider
        v-slot="{ validate, errors }"
        :rules="companyTypeDoc.mandatory ? 'required' : ''"
      >
        <!-- This is temporary solution, until I go deep into vee-validation -->
        <input
          class="not-visible"
          v-model="$store.getters.adminRegistrationData[companyTypeDoc.key]"
          readonly
          type="text"
        />
        <div :id="companyTypeDoc.key" class="droppable" v-on="targetListeners">
          <div
            v-if="!$store.getters.adminRegistrationData[companyTypeDoc.key]"
            class="empty"
          >
            <span class="text">{{ $t('serviceProvider.signUp.dropOr') }}</span>
            <ButtonElement @click="toggleFileBrowse(companyTypeDoc.key)" link>
              {{ $t('serviceProvider.signUp.browse') }}
              <input
                :id="companyTypeDoc.key + '-input'"
                type="file"
                class="d-none"
                @change="setFile(companyTypeDoc.key, $event.target.files[0])"
              />
            </ButtonElement>
          </div>
          <div v-else class="text">
            {{ $store.getters.adminRegistrationData[companyTypeDoc.key].name }}
            <div class="text-remove" @click="removeFile(companyTypeDoc.key)">
              <XIcon width="12" height="12" />
            </div>
          </div>
        </div>
        <div class="error">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>
  </div>
</template>

<script>
import InputElement from '../FormControl/InputElement.vue';
import ButtonElement from '../FormControl/ButtonElement.vue';
import useDragAndDrop from '../../composables/useDragAndDrop';

export default {
  computed: {
    targetListeners() {
      const { targetListeners } = useDragAndDrop(
        {
          dropHandler: (files, action) => {
            this.setFile(action, files[0]);
          },
        },
        { dropFile: true }
      );

      return targetListeners;
    },
  },
  created() {
    this.$store.dispatch('fetchCompanyTypeDocs', {
      company_type: this.$store.getters.adminRegistrationData.companyType,
    });
  },
  methods: {
    toggleFileBrowse(key) {
      document.getElementById(key + '-input').click();
    },
    setFile(key, file) {
      if (!file) {
        return;
      }

      this.$store.commit('setAdminRegistrationData', {
        key,
        value: file,
      });
      this.$forceUpdate();
    },
    removeFile(key) {
      this.$store.commit('setAdminRegistrationData', {
        key,
        value: null,
      });
      this.$forceUpdate();
    },
  },
  components: {
    ButtonElement,
    InputElement,
  },
};
</script>

<style lang="scss" scoped>
.fourth-step {
  margin-top: 2rem;
}
.drop-item {
  margin-top: 1.5rem;
}
.label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-bottom: 0.6rem;
  display: inline-block;
}
.droppable {
  position: relative;
  border: 2px dashed #d3d3d3;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  & .text {
    margin-right: 5px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
    color: $hty-dark-grey;
  }

  .text-remove {
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
  }
}
.error {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.2;
  color: $hty-red2;
}
.not-visible {
  visibility: hidden;
}
</style>
