<template>
  <div class="wizard-content__wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 2,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-content__wrapper {
  position: relative;
}
</style>
