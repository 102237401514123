<template>
  <div class="second-step">
    <ValidationObserver tag="form" @submit.prevent>
      <!-- First name, last name -->
      <section class="fields">
        <InputElement
          :value="$store.getters.adminRegistrationData.firstName"
          @input="$store.commit('setAdminFirstName', $event)"
          focus
          :placeholder="$t('serviceProvider.signUp.firstName')"
          validation-rules="required"
        />
        <InputElement
          :value="$store.getters.adminRegistrationData.lastName"
          @input="$store.commit('setAdminLastName', $event)"
          focus
          :placeholder="$t('serviceProvider.signUp.lastName')"
          validation-rules="required"
        />
      </section>

      <!-- Name -->
      <section class="field">
        <InputElement
          :value="$store.getters.adminRegistrationData.companyName"
          @input="$store.commit('setAdminCompanyName', $event)"
          focus
          :placeholder="$t('serviceProvider.signUp.companyName')"
          validation-rules="required"
        />
      </section>

      <!-- Entity type -->
      <section class="field mt-3">
        <ValidationProvider
          rules="required"
          v-slot="{ errors, failed, classes }"
        >
          <SelectElement
            :value="$store.getters.adminRegistrationData.companyType || ''"
            :class="{ invalid: failed }"
            @input="$store.commit('setAdminLegalEntity', $event)"
            :options="legalEntityOptions"
            search
            :placeholder="'Unternehmensart'"
          />
          <div v-if="failed" class="error" :class="classes">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </section>

      <!-- Email -->
      <section class="field">
        <InputElement
          :value="$store.getters.adminRegistrationData.email"
          @input="$store.commit('setAdminEmail', $event)"
          focus
          placeholder="Email"
          validation-rules="required|email|checkIsUsed"
          type="email"
        />
      </section>

      <!-- Password -->
      <section class="fields">
        <InputElement
          :value="$store.getters.adminRegistrationData.password"
          @input="$store.commit('setAdminPassword', $event)"
          type="password"
          focus
          name="confirm"
          :placeholder="$t('serviceProvider.signUp.password')"
          validation-rules="required|min:8"
          validation-name="password"
        />
        <InputElement
          :value="$store.getters.adminRegistrationData.confirmPassword"
          @input="$store.commit('setAdminConfirmPassword', $event)"
          type="password"
          focus
          :placeholder="$t('serviceProvider.signUp.confirmPassword')"
          validation-rules="required|password:@password"
          validation-name="confirm"
        />
      </section>

      <!-- Phone number -->
      <section class="field">
        <InputElement
          :value="$store.getters.adminRegistrationData.phoneNumber"
          @input="$store.commit('setAdminPhoneNumber', $event)"
          focus
          :placeholder="$t('serviceProvider.signUp.phoneNumber')"
          validation-rules="required|phone"
        />
      </section>

      <!-- Mobile phone -->
      <section class="field">
        <InputElement
          :value="$store.getters.adminRegistrationData.mobileNumber"
          @input="$store.commit('setAdminMobileNumber', $event)"
          focus
          :placeholder="$t('serviceProvider.signUp.mobilePhoneNumberOptional')"
          validation-rules="phone"
        />
      </section>

      <!-- Terms checkbox -->
      <ValidationProvider rules="requiredCustom" v-slot="{ errors, failed }">
        <section class="toc">
          <CheckboxElement
            v-model="acceptToc"
            validation-rules="requiredCustom"
            validation-name="acceptToc"
          />
          <span class="text">
            Ich habe die
            <a href="https://hoodty.devla.dev/docs/AGB.html" target="_blank"
              >AGBs</a
            >
            und
            <a
              href="https://hoodty.devla.dev/docs/Datenschutz.html"
              target="_blank"
              >Datenschutzerklärung</a
            >
            gelesen und akzeptiere diese
          </span>
        </section>
        <div v-if="failed" class="error">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import InputElement from '../FormControl/InputElement.vue';
import CheckboxElement from '../FormControl/CheckboxElement.vue';
import { legalEntityOptions } from '@/assets/legalEntities';
import SelectElement from '../FormControl/SelectElement/SelectElement.vue';

export default {
  computed: {
    legalEntityOptions: () => legalEntityOptions,
  },
  setup() {
    const acceptToc = ref(false);

    return {
      acceptToc,
    };
  },
  components: {
    CheckboxElement,
    InputElement,
    SelectElement,
  },
};
</script>

<style lang="scss" scoped>
.second-step {
  margin-top: 2rem;
}
.fields {
  display: flex;
  margin-bottom: 1.2rem;

  > :first-child {
    margin-right: 20px;
  }
}
.field {
  margin-bottom: 1.2rem;
}
.toc {
  display: flex;
  > :first-child {
    margin-right: 0.5rem;
  }

  a {
    color: #e58413;
  }
}
</style>
