<template>
  <div class="register-first">
    <section class="sub-title">
      {{ $t('serviceProvider.signUp.selectCompanyType') }}
    </section>
    <ValidationProvider rules="requiredCustom" v-slot="{ errors, failed }">
      <section class="options-list">
        <div class="item">
          <span class="radio">
            <RadioElement
              radio-value="service_provider"
              name="companyType"
              :value="$store.getters.adminRegistrationData.type"
              @input="$store.commit('setAdminCompanyType', $event)"
            />
          </span>
          <span class="text">{{
            $t('serviceProvider.signUp.serviceProvider')
          }}</span>
        </div>
        <div class="item">
          <span class="radio">
            <RadioElement
              radio-value="retailer"
              name="companyType"
              :value="$store.getters.adminRegistrationData.type"
              @input="$store.commit('setAdminCompanyType', $event)"
            />
          </span>
          <span class="text">{{ $t('serviceProvider.signUp.retailer') }}</span>
        </div>
      </section>
      <div v-if="failed" class="error">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import RadioElement from '../FormControl/RadioElement.vue';

export default {
  components: {
    RadioElement,
  },
  props: {
    companyType: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-orange;
  margin-top: 2rem;
}
.options-list {
  margin-top: 1rem;

  > :last-child {
    margin-top: 1rem;
  }
}
.item {
  display: flex;
  align-items: center;
}
.text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-black;
  margin-left: 20px;
}
</style>
