<template>
  <div class="register-wrapper">
    <header class="header">
      <LogoIcon color="white" />
    </header>
    <div
      class="register"
      :class="{ user: role === 'user', admin: role !== 'user' }"
    >
      <main class="register-box">
        <ValidationObserver ref="registerForm">
          <WizardHeader :steps="steps" :current-step="currentStep">
            <template #above>
              <span v-if="currentStep === 4" class="skip-button">
                <ButtonElement @click="handleSkip" link>
                  {{ $t('serviceProvider.signUp.skip') }}
                </ButtonElement>
              </span>
            </template>
          </WizardHeader>
          <WizardContent>
            <div class="content">
              <section class="title">
                {{ $t('serviceProvider.signUp.signUp') }}
              </section>
              <RegisterFirstStep v-if="currentStep === 1" />
              <RegisterSecondStep v-else-if="currentStep === 2" />
              <RegisterThirdStep v-else-if="currentStep === 3" />
              <RegisterFourthStep v-else-if="currentStep === 4" />
            </div>
          </WizardContent>

          <div>
            <ResponseAlert
              v-if="
                $store.getters.errorResponse.action == 'registerCompanyUser'
              "
              :response="$store.getters.errorResponse.data"
              class="mt-3"
            ></ResponseAlert>
          </div>

          <WizardFooter :steps="steps">
            <template #content>
              <div class="footer-main mt-3">
                <ButtonElement
                  v-if="currentStep === 1"
                  orange-secondary
                  @click="handleCancel"
                >
                  {{ $t('serviceProvider.signUp.cancel') }}
                </ButtonElement>
                <ButtonElement
                  v-else
                  @click="handlePreviousStep"
                  orange-secondary
                >
                  {{ $t('serviceProvider.signUp.previous') }}
                </ButtonElement>
                <ButtonElement
                  v-if="currentStep < steps"
                  @click="handleNextStep"
                  orange
                >
                  {{ $t('serviceProvider.signUp.next') }}
                </ButtonElement>
                <ButtonElement
                  v-else
                  @click="handleSubmit"
                  :loading="$store.getters.loading"
                  orange
                >
                  {{ $t('serviceProvider.signUp.submit') }}
                </ButtonElement>
              </div>
            </template>
            <template #below>
              <div class="footer-sub">
                <!-- <span class="sub-footer-text">{{
                  $t('serviceProvider.signUp.alreadyHaveAnAccountLog')
                }}</span> -->
                <span style="text-align: center">
                  {{ $t('serviceProvider.signUp.alreadyHaveAnAccount') }}
                  <ButtonElement
                    :anchor="{ name: 'Login', params: { role: 'admin' } }"
                    link
                  >
                    {{ $t('serviceProvider.signUp.loginHere') }}
                  </ButtonElement>
                </span>
              </div>
            </template>
          </WizardFooter>
        </ValidationObserver>
      </main>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from '@vue/composition-api';
import LogoIcon from '../components/Svgs/LogoIcon.vue';
import WizardHeader from '../components/WizardElements/WizardHeader.vue';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import WizardFooter from '../components/WizardElements/WizardFooter.vue';
import WizardContent from '../components/WizardElements/WizardContent.vue';
import RegisterFirstStep from '../components/Register/RegisterFirstStep.vue';
import RegisterSecondStep from '../components/Register/RegisterSecondStep.vue';
import RegisterThirdStep from '../components/Register/RegisterThirdStep.vue';
import RegisterFourthStep from '../components/Register/RegisterFourthStep.vue';
import store from '../store';
import router from '../router';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  name: 'Register',
  setup(props, { root }) {
    const currentStep = ref(1);
    const steps = ref(4);
    const companyType = ref('');
    const registerForm = ref(null);

    const handleNextStep = async () => {
      const valid = await registerForm.value.validate();
      console.log('valid :>> ', valid);
      if (!valid) return;
      currentStep.value = currentStep.value + 1;
      await root.$nextTick();
      registerForm.value.reset();
    };

    const handlePreviousStep = async () => {
      currentStep.value = currentStep.value - 1;
      await root.$nextTick();
      registerForm.value.reset();
    };

    const handleSubmit = async () => {
      const valid = await registerForm.value.validate();
      if (!valid) return;

      await store
        .dispatch('registerCompanyUser', store.getters.adminRegistrationData)
        .then(() => {
          store.commit('clearAdminRegistrationData');
          router.push({
            name: 'Login',
            params: { role: 'admin' },
            query: { registered: 1 },
          });
        })
        .catch(() => {
          alert('err');
        });
    };

    const handleSkip = async () => {
      await registerForm.value.reset();

      await store
        .dispatch('registerCompanyUser', store.getters.adminRegistrationData)
        .then(() => {
          store.commit('clearAdminRegistrationData');
          router.push({
            name: 'Login',
            params: { role: 'admin' },
            query: { registered: 1 },
          });
        })
        .catch(() => {
          alert('err');
        });
    };

    const handleCancel = async () => {
      router.push({
        name: 'Login',
        params: { role: 'admin' },
      });
    };

    return {
      currentStep,
      steps,
      companyType,
      registerForm,
      handleNextStep,
      handlePreviousStep,
      handleSubmit,
      handleSkip,
      handleCancel,
    };
  },
  created() {
    this.$store.commit('setErrorResponse', {});
    this.$store.commit('clearAdminRegistrationData');
  },
  components: {
    RegisterFourthStep,
    RegisterThirdStep,
    RegisterSecondStep,
    RegisterFirstStep,
    WizardFooter,
    WizardHeader,
    WizardContent,
    LogoIcon,
    ButtonElement,
    ResponseAlert,
  },
  props: {
    role: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;

  &.admin {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('../assets/pics/pexels-burst-374006.jpg');
  }
  &.user {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('../assets/pics/pexels-fauxels-3184634.png');
  }
}
.header {
  position: fixed;
  top: 20px;
  left: 20px;
}

.register-box {
  background-color: white;
  border-radius: 10px;
  max-width: 460px;
  margin: 70px auto 0;
  padding: 30px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.skip-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.footer-main {
  display: flex;
  justify-content: space-between;

  > * {
    width: 100%;
  }

  > :first-child {
    margin-right: 20px;
  }
}
.footer-sub {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  > :first-child {
    margin-right: 10px;
  }
}
.content {
  margin-top: 1rem;
}
.sub-footer-text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  text-align: center;
}
</style>
