<template>
  <div class="third-step">
    <section class="field">
      <ValidationProvider rules="required" v-slot="{ errors, failed, classes }">
        <SelectElement
          :value="$store.getters.adminRegistrationData.country"
          @input="$store.commit('setAdminCountry', $event)"
          :options="$store.getters.countriesOptions"
          :placeholder="$t('serviceProvider.signUp.country')"
          search
        />
        <div v-if="failed" class="error" :class="classes">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>
    <section class="field">
      <GooglePlacesAutocomplete
        :value="$store.getters.adminRegistrationData.city"
        :placeholder="$t('serviceProvider.signUp.city')"
        searchType="(cities)"
        @select="
          $store.commit('setAdminCity', $event.structured_formatting.main_text)
        "
      >
        <template #list-label="{ value }">
          {{ value.description }}
        </template>
      </GooglePlacesAutocomplete>
    </section>
    <section class="field">
      <GooglePlacesAutocomplete
        :value="$store.getters.adminRegistrationData.address"
        searchType="address"
        @select="
          $store.commit(
            'setAdminAddress',
            $event.structured_formatting.main_text
          )
        "
        :placeholder="$t('serviceProvider.signUp.address')"
        validation-rules="required"
      >
        <template #list-label="{ value }">
          {{ value.description }}
        </template>
      </GooglePlacesAutocomplete>
    </section>
    <section class="field">
      <InputElement
        :value="$store.getters.adminRegistrationData.zipcode"
        @input="$store.commit('setAdminZipcode', $event)"
        :placeholder="$t('serviceProvider.signUp.zipCode')"
        validation-rules="required"
      />
    </section>
    <section class="field">
      <ValidationProvider rules="required" v-slot="{ errors, failed, classes }">
        <SelectElement
          :value="$store.getters.categoryArray"
          @input="$store.commit('setAdminCategory', $event)"
          :options="$store.getters.categoriesOptions"
          multi-select
          search
          :placeholder="$t('serviceProvider.signUp.businessCategory')"
          @dropdown-closed="handleFetchSubcategory"
        />
        <div v-if="failed" class="error" :class="classes">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>
    <section class="field">
      <ValidationProvider rules="required" v-slot="{ errors, failed, classes }">
        <SelectElement
          :value="$store.getters.subcategoryArray"
          @input="$store.commit('setAdminSubcategory', $event)"
          :options="$store.getters.subcategoriesOptions"
          multi-select
          search
          :placeholder="$t('serviceProvider.signUp.businessSubCategory')"
        />
        <div v-if="failed" class="error" :class="classes">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from '@vue/composition-api';
import SelectElement from '../FormControl/SelectElement/SelectElement.vue';
import InputElement from '../FormControl/InputElement.vue';
import GooglePlacesAutocomplete from '@/components/FormControl/SelectElement/GooglePlacesAutocomplete.vue';
import store from '../../store';

export default {
  setup(props, { emit }) {
    onMounted(async () => {
      store.dispatch('fetchCategories', {
        type: store.getters.adminRegistrationData.type,
      });
      store.dispatch('fetchCountries');
    });

    const handleFetchSubcategory = async () => {
      await store.dispatch(
        'fetchSubcategoriesByCategoryIds',
        store.getters.adminRegistrationData.category
      );
    };

    return {
      handleFetchSubcategory,
    };
  },
  components: {
    InputElement,
    SelectElement,
    GooglePlacesAutocomplete,
  },
};
</script>

<style lang="scss" scoped>
.third-step {
  margin-top: 2rem;

  > * {
    margin-top: 1.5rem;
  }
}
</style>
