<template>
  <div class="wizard-footer__wrapper">
    <slot name="above" />
    <slot name="content" />
    <slot name="below" />
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 2,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-footer__wrapper {
  position: relative;
}
</style>
